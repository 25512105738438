"use client";
import React, { useState, memo } from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineMail,
  AiOutlineEnvironment,
  AiOutlineShop,
  AiOutlineClose,
  AiOutlineMenu,
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { BiLeaf } from "react-icons/bi";
import useScreenSize from "../../useScreenSize";

// Lazy load images when they are in the viewport
const LazyLoadImage = ({ src, alt, width, height }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      loading="lazy"
      className={`transition-opacity duration-500 ${loaded ? "opacity-100" : "opacity-0"}`}
      onLoad={() => setLoaded(true)}
    />
  );
};

const Header = memo(() => {
  const logo = "https://ik.imagekit.io/pcet3dvcu/global/1.png?updatedAt=1726258240115";
  const certification = "https://ik.imagekit.io/pcet3dvcu/product/certified_72x.png?updatedAt=1726258061062";

  const { isLargeScreen, isXlScreen } = useScreenSize();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setActiveLink(""); // Reset the active link when closing the menu
  };

  const socialIcons = [
    { icon: AiFillFacebook, href: "https://www.facebook.com/egy.herbs.global?mibextid=LQQJ4d" },
    { icon: AiOutlineTwitter, href: "https://x.com/egyherbsglobal?s=21&t=PAO3PFihRAQnJvLEBD-sJg" },
    { icon: AiFillLinkedin, href: "https://www.linkedin.com/company/egy-herbs-global/" },
    { icon: AiFillInstagram, href: "https://www.instagram.com/egy.herbsglobal?igsh=MWg2aW5jdWt0b3ZxZA==" },
    { icon: AiOutlineWhatsApp, href: "https://wa.me/201222297357" },
  ];

  const paths = ["/products", "/supplyChain", "/about", "/contact"];

  return (
    <header className={`bg-[#fcfbf9] text-[#183028] fixed top-0 left-0 right-0 z-50 shadow-md flex justify-between xl:items-center lg:items-end sm:items-center p-4 lg:pt-9 lg:px-10 xl:py-[30px] xl:px-[100px] transition-all duration-300 ease-in-out`}>
      {isXlScreen ? (
        <>
          <div className="flex items-center gap-10">
            <Link to="/" className="icon-Link transition-transform hover:scale-105">
              <AiOutlineMail className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link to="/" className="icon-Link transition-transform hover:scale-105">
              <AiOutlineEnvironment className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link to="/products" className="hero_title text-[#000] hover:border-[#b0603b] transition-all">PRODUCTS</Link>
            <Link to="/supplyChain" className="hero_title text-[#000] hover:border-[#b0603b] transition-all">PROCESS</Link>
          </div>

          <Link to="/" className="flex justify-center transition-transform hover:scale-105">
            <LazyLoadImage src={logo} alt="Logo" width={150} />
          </Link>

          <div className="flex items-center gap-10">
            <Link to="/about" className="hero_title text-[#000] hover:border-[#b0603b] transition-all">ABOUT</Link>
            <Link to="/contact" className="hero_title text-[#000] hover:border-[#b0603b] transition-all">MEET YOUR HERBS</Link>
            <Link to="/" className="icon-Link transition-transform hover:scale-105">
              <AiOutlineShop className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link to="/products" className="icon-Link transition-transform hover:scale-105">
              <BiLeaf className="icon text-[#205c40] text-2xl" />
            </Link>
          </div>
        </>
      ) : isLargeScreen ? (
        <>
          <div className="flex gap-14">
            <Link to="/" className="transition-transform hover:scale-105">
              <LazyLoadImage src={logo} alt="Logo" width={150} />
            </Link>

            <div className="flex items-end gap-10">
              <Link to="/products" className="hero_title text-[#000] hover:border-[#b0603b] transition-all">PRODUCTS</Link>
              <Link to="/about" className="hero_title text-[#000] hover:border-[#b0603b] transition-all">ABOUT</Link>
              <Link to="/supplyChain" className="hero_title text-[#000] hover:border-[#b0603b] transition-all">PROCESS</Link>
              <Link to="/contact" className="hero_title text-[#000] hover:border-[#b0603b] transition-all">MEET YOUR HERBS</Link>
            </div>
          </div>

          <div className="flex gap-7">
            <Link to="/" className="icon-Link transition-transform hover:scale-105">
              <AiOutlineMail className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link to="/" className="icon-Link transition-transform hover:scale-105">
              <AiOutlineEnvironment className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link to="/products" className="icon-Link transition-transform hover:scale-105">
              <BiLeaf className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link to="/" className="icon-Link transition-transform hover:scale-105">
              <AiOutlineShop className="icon text-[#205c40] text-2xl" />
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center fixed top-0 left-0 right-0 z-50 bg-[#fcfbf9] px-5 py-4 shadow-sm">
            <Link to="/" className="transition-transform hover:scale-105" onClick={() => setIsMenuOpen(false)}>
              <LazyLoadImage src={logo} alt="Logo" width={130} />
            </Link>
            <div className="flex items-center gap-3">
              <Link to="/" className="icon-Link transition-transform hover:scale-105" onClick={() => setIsMenuOpen(false)}>
                <AiOutlineMail className="icon text-[#205c40] text-2xl" />
              </Link>
              <Link to="/" className="icon-Link transition-transform hover:scale-105" onClick={() => setIsMenuOpen(false)}>
                <AiOutlineEnvironment className="icon text-[#205c40] text-2xl" />
              </Link>
              <Link to="/products" className="icon-Link transition-transform hover:scale-105" onClick={() => setIsMenuOpen(false)}>
                <AiOutlineShop className="icon text-[#205c40] text-2xl" />
              </Link>
              <Link to="/products" className="icon-Link transition-transform hover:scale-105" onClick={() => setIsMenuOpen(false)}>
                <BiLeaf className="icon text-[#205c40] text-2xl" />
              </Link>
              <button onClick={toggleMenu} className="text-xl text-[#205c40]">
                {isMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
              </button>
            </div>
          </div>

          {/* Fullscreen Menu */}
          <div
            className={`fixed inset-0 bg-[#fcfbf9] z-40 flex flex-col items-center gap-16 mt-[80px] transition-all duration-500 ease-in-out ${
              isMenuOpen ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
            }`}
          >
            <div className="flex flex-col justify-center w-full">
              {["PRODUCTS", "PROCESS", "ABOUT", "MEET YOUR HERBS"].map((label, index) => (
                <div key={index} className="flex justify-between items-center p-6 shadow-sm">
                  <Link
                    to={paths[index]} // Use the defined paths here
                    onClick={() => setIsMenuOpen(false)}
                    className="hero_title"
                  >
                    {label}
                  </Link>
                </div>
              ))}
            </div>

            {/* Social Icons */}
            <div className="flex gap-4">
              {socialIcons.map(({ icon: Icon, href }, index) => (
                <a key={index} href={href} target="_blank" rel="noopener noreferrer" className="icon-Link transition-transform hover:scale-105">
                  <Icon className="icon text-[#205c40] text-2xl" />
                </a>
              ))}
            </div>
          </div>
        </>
      )}
    </header>
  );
});

export default Header;
