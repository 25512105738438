import React, { useEffect, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./globals.css";
import Header from "./Components/Header/nav";
import FloatingActionButton from "./Components/FloatingActionButton/Test";

// Lazy load components
const TenthSec = React.lazy(() =>
  import("./Components/Home-Page/10-Tenth-Sec/Tenth-Sec")
);
const HomePage = React.lazy(() => import("./Components/Home-Page/Home-Page"));
const ProductPage = React.lazy(() =>
  import("./Components/Product-Page/Product-Page")
);
const About = React.lazy(() => import("./Components/About/About"));
const Contact = React.lazy(() => import("./Components/Contact/Contact"));
const SupplyChain = React.lazy(() =>
  import("./Components/Supply-Chain/Supply-Chain")
);
// const Footer = React.lazy(() => import("./Components/Footer/Footer"));
const FooterCopy = React.lazy(() => import("./Components/Footer/FooterCopy"));

export default function App() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top on route change
    window.scrollTo(0, 0);
  }, [location]);

  const LoadingFallback = (
    <div className="loader-wrapper">
      <div className="loader"></div>
    </div>
  );

  return (
    <div className="App">
      <Header />
      <div>
        <Suspense fallback={LoadingFallback}>
          <Routes location={location}>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/supplychain" element={<SupplyChain />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Suspense>
      </div>
      <FloatingActionButton />
      <Suspense fallback={LoadingFallback}>
        <TenthSec />
        {/* <Footer /> */}
        <FooterCopy />
      </Suspense>
    </div>
  );
}
