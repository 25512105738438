import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    isLargeScreen: window.innerWidth >= 1024 && window.innerWidth < 1280,
    isXlScreen: window.innerWidth >= 1280,
  });

  const updateScreenSize = () => {
    setScreenSize({
      isLargeScreen: window.innerWidth >= 1024 && window.innerWidth < 1280,
      isXlScreen: window.innerWidth >= 1280,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screenSize;
};

export default useScreenSize;
